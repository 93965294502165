import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const arima = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Arima" />
    <h3 className='underline-title'>Arima ou (Fêlure d'altitude)</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Et alors? ...<br />
        L'as-tu vu le pays de Canaan biblique<br />
        Pays promis à leurs aieux<br />
        Canaan des oeufs de fruits<br />
        Où coule la gelée royale<br />
      </p>
      <p>
        L'as-tu abordée l'historique Cléopâtre?<br />
        Alexandrine au nez désarmant l'antique général<br />
        Fille d'Orient aux attraits magnétiques<br />
      </p>
      <p>
        L'as-tu capturé l'oiseau bleu que tu filais? <br />
        L'oiseau frêle au plumage éclatant<br />
        Et alors? ...<br />
      </p>
      <p>
        O déception!<br />
        Je n'ai vu ni Canaan<br />
        <span className="par-starting"></span>ni Cléopâtre<br />
        <span className="par-starting"></span>ni l'oiseau bleu!<br />
        Je n'ai vu que des pasteurs de l'ère archaique<br />
        <span className="par-starting"></span>et leurs vaches de la disette<br />
        Je n'ai guère vu d'autre file qu'Arima<br />
        <span className="par-starting"></span>lascive et incendiaire<br />
      </p>
      <p>
        Oui encore<br />
        J'ai vu un lac du désert et des monts fumants<br />
        Parages propices au flegme et a la dipsomanie<br />
        Contrée de laves visqueuses et fumerolles volcaniques<br />
      </p>
      <p>
        Sous un bananier aux feuilles grêlées assise Arima<br />
        Fille aux déceptions cycliques<br />
        <span className="par-starting"></span>infortunée à l'âme agitée<br />
        <span className="par-starting"></span>fille des mauvais esprits<br />
      </p>
      <p>
        Arima pourquoi as-tu rouvert ma blessure?<br />
        Elle saigne avec abondance<br />
        Elle saigne de nouveau!<br />
      </p>
      <p>
        Je dois rentrer, rentrer au village natal<br />
        Où frères et soeurs mangent à leur faim <br />
        Là-bas je descendrai à la source du village<br />
      </p>
      <p>
        Où coulent les ondes pures et fidèles<br />
        Où m'attend Titina<br />
        <span className="par-starting"></span>la douceur<br />
        <span className="par-starting"></span>la noblesse<br />
      </p>
      <p>
        Oh! l'oiseau bleu!<br />
        <span className="par-starting"></span>tu es là!<br />
        <span className="par-starting"></span>tu es dans mon village!<br />
        Loin de mon village je te cherchais!<br />
        Étais-tu donc dans mon lit!!!<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default arima
